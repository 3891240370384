// RobotoFlex-Regular.ttf

// @font-face {
//   font-family: 'thin';
//   src: url('./assets/font/avenir_ff/AvenirLTStd-Book.otf') format('truetype');
// }

// @font-face {
//   font-family: 'regular';
//   src: url('./assets/font/avenir_ff/AvenirLTStd-Roman.otf') format('truetype');
// }

// @font-face {
//   font-family: 'bold';
//   src: url('./assets/font/avenir_ff/AvenirLTStd-Black.otf') format('truetype');
// }

// @font-face {
//   font-family: 'headline';
//   src: url('./assets/font/avenir_ff/AvenirLTStd-Black.otf') format('truetype');
// }

@font-face {
  font-family: 'thin';
  src: url('./assets/font/Roboto_Flex/RobotoFlex-Regular.ttf') format('truetype');
  font-weight: thin;
}

@font-face {
  font-family: 'regular';
  src: url('./assets/font/Roboto_Flex/RobotoFlex-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'bold';
  src: url('./assets/font/Roboto_Flex/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'headline';
  src: url('./assets/font/Roboto_Flex/RobotoFlex-Regular.ttf') format('truetype');
}
